body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.mapboxgl-ctrl-group > button {
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
}

.mapbox-gl-draw_polygon {
  background-image: url('./pages/campaign-upload-details/assets/outer-border.svg');
}

.mapbox-gl-draw_trash {
  background-image: url('./pages/campaign-upload-details/assets/trash-bin.svg');
}

.mapbox-gl-draw_polygon {
  background-color: #ecf1f7 !important;
}

.mapbox-gl-draw_polygon.active {
  background-image: url('./pages/campaign-upload-details/assets/outer-border-active.svg');
}

.mapbox-gl-draw_trash {
  background-color: #ecf1f7 !important;
}

.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-in-review {
  background-image: url('./pages/campaign-upload-details/assets/marker-icon.svg');
}

.marker-rejected {
  background-image: url('./pages/campaign-upload-details/assets/marker-rejected-icon.svg');
}

.marker-approved {
  background-image: url('./pages/campaign-upload-details/assets/marker-approved-icon.svg');
}

.marker-skipped {
  background-image: url('./pages/campaign-upload-details/assets/marker-skipped-icon.svg');
}

.marker-selected {
  background-image: url('./pages/campaign-upload-details/assets/marker-selected-icon.svg') !important;
}

.remove-marker-button {
  background: red;
  border: 0;
  color: #fff;
  width: 100%;
  height: 32px;
  font-weight: bold;
  cursor: pointer;
}

.generate-files-button {
  background: #FFE137 !important;
  color: #191408 !important;
  text-align: center !important;
}

.generate-files-spinner > div {
  border-top: solid 2px #fff;
}

.sampling-point-popup, .mapboxgl-popup-content {
  width: 150px;
  font-family: 'Noto Sans', sans-serif;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn .5s;
}
